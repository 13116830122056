/*
 * ESLINT
 */
/* eslint-disable max-lines */


/*
 * IMPORTS
 */
import ThemeGet from '@styled-system/theme-get' // NPM: styled-system themeGet library.
import { createGlobalStyle } from 'styled-components' // NPM: styled-components library.


/*
 * OBJECTS
 */
const Index = createGlobalStyle`

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  *:focus {
    outline: none;
  }

  ::selection {
    background: ${ThemeGet('colors.lightestBlack')};
    color: ${ThemeGet('colors.white')};
  }

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
  }

  html {
    box-sizing: border-box;

  }

  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    tap-highlight-color: transparent;
    letter-spacing: 0.5px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Poppins', Helvetica, Arial, sans-serif;
    background-color: ${ThemeGet('colors.black')};
  }

  #__next {
    position: relative;
    overflow-x: hidden;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  @keyframes blinkHide {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
  }

  @keyframes blinkShow {
    0% {
      opacity: 1;
    }
    10% {
      opacity: 0;
    }
  }

  @keyframes rotateIt {
    to {
      transform: rotate(-360deg);
    }
  }

  @keyframes moveUpAndDownArrow {
    0%,
    100% {
      transform: translateY(-2.5px);
    }
    50% {
      transform: translateY(2.5px);
    }
  }

  @keyframes fadeIn {
    100% {
      transform: scale(1.03);
      opacity: 0;
    }
  }

  @keyframes scaleAndRotateArrow {
    from {
      opacity: 0;
      transform: rotate(0deg);
    }
    to {
      opacity: 1;
      transform: rotate(-120deg);
    }
  }

  @keyframes moveArrowForwardAndBackward {
    from {
      right: -5px;
    }
    to {
      right: 5px;
    }
  }

  @keyframes animateInTopLeft {
    from {
      transform: translate3d(0, 0, 0);
    }
    to {
      transform: translate3d(5px, -5px, -5px);
    }
  }

  @keyframes animeOutTopLeft {
    from {
      transform: translate3d(5px, -5px, -5px);
    }
    to {
      transform: translate3d(0, 0, 0);
    }
  }

  .reusecore__button {
    width: max-content;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
    margin-left: 0;
    margin-right: 0;
    font-size: 15px;
    display: flex;
    border-radius: 9px;
    border-color: transparent;
    background: ${ThemeGet('colors.primary')};
    padding: 15px 20px;
    text-transform: capitalize;
    transition: box-shadow 0.3s ease-in-out, background 0.3s ease-in-out;
    box-shadow: 0 20px 35px ${ThemeGet('colors.blackShadow')};
    pointer-events: auto;
    cursor: pointer;
    position: relative;

    &::before,
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    & span {
      font-weight: 600;
    }

    &:hover {
      background: transparent !important;
      box-shadow: none;
    }

    &.white {
      background: ${ThemeGet('colors.white')};
      box-shadow: 0 20px 35px ${ThemeGet('colors.blackShadow')};

      & span {
        font-weight: 600;
        color: ${ThemeGet('colors.black')};
      }

      &:hover {
        background: ${ThemeGet('colors.white')};
      }
    }

    &.black {
      background: ${ThemeGet('colors.black')};
      box-shadow: 0 20px 35px ${ThemeGet('colors.blackShadow')};

      & span {
        font-weight: 600;
        color: ${ThemeGet('colors.white')};
      }
    }

    &.theme {
      background: ${ThemeGet('colors.primary')};
      box-shadow: 0 20px 35px ${ThemeGet('colors.blackShadow')};

      & span {
        font-weight: 600;
        color: ${ThemeGet('colors.white')};
      }

      &.secondary {
        background: ${ThemeGet('colors.secondary')};
        box-shadow: 0 20px 35px ${ThemeGet('colors.blackShadow')};

        & span {
          font-weight: 600;
          color: ${ThemeGet('colors.pureDarkBlack')};
        }
      }
    }

    &.success {
      background: ${ThemeGet('colors.success')};

      & span {
        font-weight: 600;
        color: ${ThemeGet('colors.success')};
      }
    }

    &.danger {
      background: ${ThemeGet('colors.danger')};

      & span {
        font-weight: 600;
        color: ${ThemeGet('colors.danger')};
      }
    }

    &.gradientRound {
      font-size: 15px;
      border: none;
      display: flex;
      font-weight: 600;
      margin: auto;
      gap: 5px;
      background: transparent;
      align-items: center;
      border-radius: 25px !important;

      & span {
        color: ${ThemeGet('colors.pureWhite')};
        position: relative;
        z-index: 10;
        & svg {
          fill: ${ThemeGet('colors.pureWhite')};
        }
      }

      &:hover {
        &:after {
          filter: opacity(1.0);
          transform: scale(1.1);
        }

        & svg {
          position: relative;
          animation: moveArrowForwardAndBackward 1s cubic-bezier(.19, 1, .22, 1) infinite alternate;
        }
      }

      &:after {
        content: ' ';
        background: linear-gradient(90deg, ${ThemeGet('colors.secondary')} 0%, ${ThemeGet('colors.primary')} 65.1%, ${ThemeGet('colors.ternary')} 100%);
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 0;
        border-radius: 25px !important;
        filter: opacity(0.9);
        transition: transform .5s cubic-bezier(.19, 1, .22, 1), opacity .5s cubic-bezier(.19, 1, .22, 1);
      }
      &.leftTopRight {
        &:after {
        background: linear-gradient(90deg, ${ThemeGet('colors.primary')} 0%, ${ThemeGet('colors.primary')} 65.1%, ${ThemeGet('colors.secondary')} 100%);
        }
      }
      &.topToDown {
        & > span {
          color: ${ThemeGet('colors.pureBlack')};
        }
        &:after {
          background: linear-gradient(45deg, ${ThemeGet('colors.secondary')} 0%, ${ThemeGet('colors.secondary')} 65.1%, ${ThemeGet('colors.primary')} 100%);
        }
      }
    }

    &.surtur {
      padding: 0;
      position: relative;
      background: none;
      clip-path: circle(40% at 50% 50%);

      & .circle {
        position: relative;
        display: block;
        width: 150px;

        & text {
          font-size: 41px;
          text-transform: uppercase;
          fill: ${ThemeGet('colors.white')};
        }
      }

      & .center {
        position: absolute;
        z-index: 10;
        width: 60px;
        height: 60px;
        top: calc(50% - 15px);
        left: calc(50% - 15px);
      }

      & .eye {
        position: absolute;
        z-index: 10;
        width: 60px;
        height: 60px;
        top: calc(50% - 30px);
        left: calc(50% - 30px);

        & .outer,
        & .inner,
        & .lashesUp,
        & .lashesDown {
          stroke: ${ThemeGet('colors.white')};
          fill: none;
          stroke-width: 1.5px;
        }

        & .lashesDown {
          opacity: 0;
        }
      }

      &:focus-visible {
        background: ${ThemeGet('colors.primary')};
      }

      &:hover,
      &.preload {
        & .circle {
          animation: rotateIt 7s linear infinite;
        }

        & .eye .lashesUp,
        & .eye .inner,
        & .eye .iris {
          animation: blinkHide 2s step-end infinite;
        }

        & .eye .lashesDown {
          animation: blinkShow 2s step-end infinite;
        }

        & .arrow {
          animation: moveUpAndDownArrow 1.5s infinite;
        }
      }
    }
  }

  .subHeading {
    text-transform: uppercase;
    justify-content: center;
    margin-right: auto;
    margin-bottom: 0;
    margin-left: 0;
    position: relative;
    text-align: left;
    font-size: 13px;
    width: min-content;
    font-weight: 600;
    box-shadow: 0 20px 35px ${ThemeGet('colors.blackShadow')};
    background-color: ${ThemeGet('colors.primary')};
    border-radius: 25px;
    padding: 2.5px 10px !important;
    color: ${ThemeGet('colors.white')};

    &.revert {
      &::before {
        left: unset;
        right: -30px;
      }
    }

    &.transparent {
      padding: 0 !important;
      background-color: transparent;

      &::before {
        background-color: transparent;
      }

      &.pureWhite {
        color: ${ThemeGet('colors.pureWhite')} !important;
      }

      &.followedLine {
        &::after {
          content: ' ';
          width: 135px;
          height: 0;
          margin-left: 10px;
          background-clip: text;
          text-fill-color: transparent;
          border-bottom: 2px solid transparent;
          border-image: linear-gradient(90deg, ${ThemeGet('colors.primary')} 0%, ${ThemeGet('colors.ternary')} 65.1%, ${ThemeGet('colors.secondary')} 100%);
          border-image-slice: 1;
        }
      }
    }

    &.white {
      background-color: ${ThemeGet('colors.white')};
      color: ${ThemeGet('colors.black')} !important;

      &::before {
        background-color: ${ThemeGet('colors.white')};
      }
    }

    &.theme {
      background-color: ${ThemeGet('colors.primary')};
      color: ${ThemeGet('colors.white')} !important;
      box-shadow: 0 0 30px 3px ${ThemeGet('colors.blackShadow')};

      &::before {
        background-color: ${ThemeGet('colors.primary')};
        box-shadow: 0 0 30px 3px ${ThemeGet('colors.blackShadow')};
      }

      &.secondary {
        background-color: ${ThemeGet('colors.secondary')};
        color: ${ThemeGet('colors.pureDarkBlack')} !important;
        box-shadow: 0 0 30px 3px ${ThemeGet('colors.blackShadow')};

        &::before {
          background-color: ${ThemeGet('colors.secondary')};
          box-shadow: 0 0 30px 3px ${ThemeGet('colors.blackShadow')};
        }
      }
    }

    &.lightestBlack {
      background-color: ${ThemeGet('colors.lightestBlack')};
      color: ${ThemeGet('colors.white')};
      box-shadow: 0 0 30px 3px ${ThemeGet('colors.blackShadow')};

      &::before {
        background-color: ${ThemeGet('colors.lightestBlack')};
        box-shadow: 0 0 30px 3px ${ThemeGet('colors.blackShadow')};
      }
    }

    &.black {
      background-color: ${ThemeGet('colors.black')};
      color: ${ThemeGet('colors.white')};
      box-shadow: 0 0 30px 3px ${ThemeGet('colors.blackShadow')};

      &::before {
        background-color: ${ThemeGet('colors.black')};
        box-shadow: 0 0 30px 3px ${ThemeGet('colors.blackShadow')};
      }
    }

    &.pureBlack {
      background-color: ${ThemeGet('colors.pureBlack')};
      color: ${ThemeGet('colors.pureWhite')} !important;
      box-shadow: 0 0 30px 3px ${ThemeGet('colors.blackShadow')};

      &::before {
        background-color: ${ThemeGet('colors.pureBlack')};
        box-shadow: 0 0 30px 3px ${ThemeGet('colors.blackShadow')};
      }
    }

    &.danger {
      background-color: ${ThemeGet('colors.danger')};
      color: ${ThemeGet('colors.white')} !important;
      box-shadow: 0 0 30px 3px ${ThemeGet('colors.blackShadow')};

      &::before {
        background-color: ${ThemeGet('colors.danger')};
        box-shadow: 0 0 30px 3px ${ThemeGet('colors.blackShadow')};
      }
    }

    @media (max-width: 766px) {
      font-size: 14px;
    }
    @media (max-width: 556px) {
      font-size: 13px;
    }
  }

  .heading,
  .selfWritingHeading {
    font-size: 70px;
    font-weight: 500 !important;
    letter-spacing: 0.015em;
    line-height: 1.2;
    text-align: left;
    margin-top: 30px !important;
    margin-right: 0;
    width: auto;
    margin-bottom: 0;
    position: relative;
    margin-left: 0;
    color: ${ThemeGet('colors.primary')};

    &.danger {
      color: ${ThemeGet('colors.danger')};
    }

    @media (max-width: 1300px) and (min-width: 766px) {
      font-size: 81px;
    }
    @media (max-width: 771px) {
      font-size: 71px;
    }
    @media (max-width: 661px) {
      font-size: 31px;
    }
  }

  .selfWritingHeading {
    font-weight: 200;
  }

  * ~ .subHeading,
  .subHeading ~ * {
    margin-top: 10px !important;
  }

  .subHeading ~ .heading {
    margin-top: 10px !important;
  }

  .description {
    font-size: 18px;
    line-height: 1.75;
    font-weight: 400;
    text-align: left;
    width: 60%;
    margin-top: 40px !important;
    margin-right: auto;
    margin-bottom: 0;
    margin-left: 0;
    color: ${ThemeGet('colors.black')};
    @media (max-width: 1220px) {
      width: 70%;
    }
    @media (max-width: 661px) {
      width: 100%;
    }
  }

  .description ~ .reusecore__button {
    margin-top: 60px !important;
  }

  .dIcon {
    position: relative;
    height: 90px;
    width: 90px;
    display: flex;
    align-items: center;
  }

  .dBanner {
    border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
    width: calc(100vw / 1.5);
    height: calc(100vw / 1.5);
    position: absolute;
    margin: auto;
    will-change: border-radius;
    z-index: 10000;

    &:before,
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
    }

    &:after {
      line-height: 120px;
      text-indent: -21px;
    }

    &.positionCenter {
      top: calc(25% - 100px);
    }

    &.positionRight {
      right: 0;
      left: unset;
    }

    &.positionBottom {
      bottom: 0;
    }

    &.positionTop {
      top: 0;
    }

    &.positionLeft {
      left: 0;
      right: unset;
    }

    @media only screen and (max-width: 991px) {
      display: none;
    }
  }

  .svg {
    &.stroke {
      &.theme {
        stroke: ${ThemeGet('colors.primary')};

        &.secondary {
          stroke: ${ThemeGet('colors.secondary')};
        }
      }

      &.white {
        stroke: ${ThemeGet('colors.white')};
      }

      &.blackShadow {
        stroke: ${ThemeGet('colors.blackShadow')};
      }

      &.darkWhite {
        stroke: ${ThemeGet('colors.darkWhite')};
      }

      &.lightDarkWhite {
        stroke: ${ThemeGet('colors.lightDarkWhite')};
      }
    }

    &.fill {
      &.theme {
        fill: ${ThemeGet('colors.primary')};

        &.secondary {
          fill: ${ThemeGet('colors.secondary')};
        }
      }

      &.white {
        fill: ${ThemeGet('colors.white')};
      }

      &.blackShadow {
        fill: ${ThemeGet('colors.blackShadow')};
      }

      &.darkWhite {
        fill: ${ThemeGet('colors.darkWhite')};
      }

      &.lightDarkWhite {
        fill: ${ThemeGet('colors.lightDarkWhite')};
      }
    }
  }

  .font.whiteSpacePre {
    white-space: pre !important;
  }

  .font.weight200 {
    font-weight: 200 !important;
  }

  .font.weight300 {
    font-weight: 300 !important;
  }

  .font.weight400 {
    font-weight: 400 !important;
  }

  .font.weight500 {
    font-weight: 500 !important;
  }

  .font.weight600 {
    font-weight: 600 !important;
  }

  .font.weight700 {
    font-weight: 700 !important;
  }

  .font.weight800 {
    font-weight: 800 !important;
  }

  .font.weight900 {
    font-weight: 900 !important;
  }

  .font.strikeThrough {
    text-decoration: line-through;
  }

  .font.black {
    span&,
    a&,
    p&,
    h1&,
    h2&,
    h3&,
    h4&,
    h5&,
    h6&,
    span,
    a,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    &.selfWritingHeading {
      color: ${ThemeGet('colors.black')};
    }

    &.subHeading {
      &::before {
        background-color: ${ThemeGet('colors.black')};
      }
    }
  }

  .font.white {
    span&,
    a&,
    p&,
    h1&,
    h2&,
    h3&,
    h4&,
    h5&,
    h6&,
    span,
    a,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    &.selfWritingHeading {
      color: ${ThemeGet('colors.white')};
    }

    &.subHeading {
      &::before {
        background-color: ${ThemeGet('colors.white')};
      }
    }
  }

  .font {
    &.onHoverGradient {
      &:hover {
        background: linear-gradient(90deg, ${ThemeGet('colors.primary')} 0%, ${ThemeGet('colors.ternary')} 65.1%, ${ThemeGet('colors.secondary')} 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
  }

  .font.white {
    span&,
    a&,
    p&,
    h1&,
    h2&,
    h3&,
    h4&,
    h5&,
    h6&,
    span,
    a,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    &.selfWritingHeading {
      color: ${ThemeGet('colors.white')};
    }

    &.subHeading {
      &::before {
        background-color: ${ThemeGet('colors.white')};
      }
    }
  }

  .font.darkerWhite {
    span&,
    a&,
    p&,
    h1&,
    h2&,
    h3&,
    h4&,
    h5&,
    h6&,
    span,
    a,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    &.selfWritingHeading {
      color: ${ThemeGet('colors.darkerWhite')};
    }

    &.subHeading {
      &::before {
        background-color: ${ThemeGet('colors.darkerWhite')};
      }
    }
  }

  .font.danger {
    span&,
    a&,
    p&,
    h1&,
    h2&,
    h3&,
    h4&,
    h5&,
    h6&,
    span,
    a,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    &.selfWritingHeading {
      color: ${ThemeGet('colors.danger')};
    }

    &.subHeading {
      &::before {
        background-color: ${ThemeGet('colors.danger')};
      }
    }
  }

  .font.pureWhite {
    span&,
    a&,
    p&,
    h1&,
    h2&,
    h3&,
    h4&,
    h5&,
    h6&,
    span,
    a,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    &.selfWritingHeading {
      color: ${ThemeGet('colors.pureWhite')};
    }

    &.subHeading {
      &::before {
        background-color: ${ThemeGet('colors.pureWhite')};
      }
    }
  }

  .font.theme {
    span&,
    a&,
    p&,
    h1&,
    h2&,
    h3&,
    h4&,
    h5&,
    h6&,
    span,
    a,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    &.selfWritingHeading {
      color: ${ThemeGet('colors.primary')};
    }

    &.subHeading {
      &::before {
        background-color: ${ThemeGet('colors.primary')};
      }
    }
  }

  .font.theme.secondary {
    span&,
    a&,
    p&,
    h1&,
    h2&,
    h3&,
    h4&,
    h5&,
    h6&,
    span,
    a,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    &.selfWritingHeading {
      color: ${ThemeGet('colors.secondary')};
    }

    &.subHeading {
      &::before {
        background-color: ${ThemeGet('colors.secondary')};
      }
    }
  }

  .font.gradient {
    span&,
    a&,
    p&,
    h1&,
    h2&,
    h3&,
    h4&,
    h5&,
    h6&,
    span,
    a,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    &.selfWritingHeading {
      background: linear-gradient(90deg, ${ThemeGet('colors.primary')} 0%, ${ThemeGet('colors.secondary')} 65.1%, ${ThemeGet('colors.secondary')} 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;

      &.topToBottom {
        background: linear-gradient(180deg, ${ThemeGet('colors.primary')} 0%, ${ThemeGet('colors.secondary')} 65.1%, ${ThemeGet('colors.secondary')} 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }

    &.subHeading {
      &::before {
        background: linear-gradient(90deg, ${ThemeGet('colors.primary')} 0%, ${ThemeGet('colors.secondary')} 65.1%, ${ThemeGet('colors.secondary')} 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
  }

  svg.hideSVG {
    display: none !important;
  }

  .margin.unset {
    margin: 0 !important;
  }

  .padding.unset {
    padding: 0 !important;
  }

  .padding.px20TopBottom {
    padding: 20px 0 !important;
  }

  .text.transform.uppercase {
    text-transform: uppercase;
  }

  .width.maxContent {
    width: max-content;
  }

  .width.minContent {
    width: min-content;
  }

  .font.capitalize {
    text-transform: capitalize;
  }

  .position.unset {
    position: unset !important;
  }

  .shadow.unset {
    box-shadow: unset !important;
  }

  .background.transparent {
    background-color: transparent !important;
  }

  div.scrollbar-track.scrollbar-track-x.show,
  div.scrollbar-track.scrollbar-track-y.show {
    display: none !important;
  }

  .reuseModalHolder {
    background-color: transparent !important;
    border: none !important;
  }
  .reuseModalCloseBtn {
    position: fixed;
    top: 60px;
    left: calc(100vw - 120px);
  }
  .ReactModal__Overlay.ReactModal__Overlay--after-open {
    overflow: auto;
    z-index: 100;
    background-color: ${ThemeGet('colors.blackShadow')} !important;
  }
  .ReactModal__Content.ReactModal__Content--after-open {
    border: none !important;
    inset: unset !important;
    background-color: ${ThemeGet('colors.black')} !important;
    left: 25% !important;
    z-index: 1000;
    border-radius: 0 !important;
    height: 100% !important;
    & > div:last-child {
      position: fixed;
      top: 60px;
      left: calc(100vw - 120px);
      width: max-content;
      & svg {
        stroke: ${ThemeGet('colors.white')} !important;
      }
    }
  }
  .layout{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
    overflow: hidden;
    & .flexOneFifth{
      flex:0.5;
      height: 100%;
      overflow: hidden auto;
      @media(max-width: 1086px){
        &:nth-child(1){
          display: none;
        }
        &:nth-child(2){
          flex: 1;
        }
      }
    }
    & .hide{
      display: none;
    }
  }

  table > tbody > tr:last-child > td {
    border-bottom-width: 0;
  }
`


/*
 * EXPORTS
 */
export default Index
