/*
 * JS
 */
;(() => {
  // Array prototype extends
  Array.indexestr = (__arr, __val) => {
    // Local variable.
    let _i

    // Const assignment.
    const _indexes = []

    // Variable assignment.
    _i = -1

    /*
     * Consider doing only if __arr is arrays
     * nothing else.
     */
    if (__arr.constructor !== Array) return __arr

    // While indexes count
    while (-1 !== (_i = __arr.indexOf(__val, _i + 1))) {
      // Push index
      _indexes.push(_i)
    }

    // Return indexes
    return _indexes
  }

  // Object prototype extends
  Object.dot = __object => ({
    get: (__path, __defaultValue) => __path.split('.').reduce((o, p) => (o ? o[p] : __defaultValue), __object),
    // eslint-disable-next-line no-cond-assign
    set: (__path, __value) => __path.split('.').reduce((o, p, i) => (o[p] = (__path.split('.').length += i) ? __value : o[p] || {}), __object)
  })
  Object.depthOf = __object => {
    // Local variable.
    let _jamaica, _level

    // Variable assignment.
    _level = 1

    // Loop over object properties.
    for (_jamaica in __object) {
      // HasOwnProperty check.
      if (!__object.hasOwnProperty(_jamaica)) continue

      /*
       * Only continue if given object value
       * is typeof object.
       */
      if ('object' === typeof __object[_jamaica]) {
        // Const assignment.
        const depthBy = 1
        const _depth = Object.depthOf(__object[_jamaica]) + depthBy

        // Variable assignment.
        _level = Math.max(_depth, _level)
      }
    }

    // Return depth.
    return _level
  }
  Object.filterOut = (__obj, __predicate) => {
    // Local variable.
    let _key

    // Const assignment.
    const result = {}

    // Loop over object keys.
    for (_key in __obj) {
      // Conditional checking for objects.
      if (__obj.hasOwnProperty(_key) && __predicate(__obj[_key])) {
        // Push object value to new set.
        result[_key] = __obj[_key]
      }
    }

    // Return result
    return result
  }

  // String prototype extends
  String.toCapitalize = function toCapitalize(__string) {
    // Variable assignment.
    __string = __string ? __string.toLowerCase() : __string

    /*
     * If given string is not empty than use it
     * else prefer this.
     */
    if (__string && 'string' === typeof __string) return __string.charAt(0).toUpperCase() + __string.slice(1)

    // Return capitalize.
    return __string ? this.charAt(0).toUpperCase() : void 0
  }
  String.toCamelCase = __string =>
    // Return replaced string.
    __string.replace(/(?:^\w|[A-Z]|\b\w|\s+)/gu, (__match, __index) => {
      // Return string on null match
      if (0 === Number(__match)) return ''

      // Return camelcase.
      return 0 === __index ? __match.toLowerCase() : __match.toUpperCase()
    })

  String.random = length => {
    // Local variable.
    let _result, i

    // Variable assignment.
    _result = ''

    // Const assignment.
    const _characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const _charactersLength = _characters.length

    // Loop over length and generate string.
    for (i = 0 ;i < length ;i++) {
      // Concat random char at given random index.
      _result += _characters.charAt(Math.floor(Math.random() * _charactersLength))
    }

    // Return result.
    return _result
  }
  // React.js object extension with useful common functions.
  Object.React = {
    'JoiErrorHandler': (__errors, __name, __cb) => {
      // Loop over error and fix them one by one.
      __errors.forEach(__error => {
        switch (__error.type) {
        case 'any.required':
          // Report failure.
          __error.message = `${__name.toUpperCase()}_INPUT_EMPTY`

          // Run given callback.
          __cb?.()

          // Break statement.
          break
        default:
          // Report failure.
          __error.message = `${__name.toUpperCase()}_INVALID_INPUT`

          // Run given callback.
          __cb?.()

          // Break statement.
          break
        }
      })

      // Return error.
      return __errors
    },
    'CopyToClipBoard': text => {
      // Copy text content to clipboard.
      if ('clipboard' in navigator) return navigator.clipboard.writeText(text)

      // Execute copy.
      return document.execCommand('copy', true, text)
    },
    'GenerateOtp': () => {
      // Variable declaration.
      let _otp, i

      // Variable assignment.
      _otp = ''

      /*
       * Loop over 4 digits to generate
       * otp.
       */
      for (i = 0 ;4 > i ;i++) _otp += '0123456789'[Math.floor(Math.random() * 10)]

      // Return generated otp.
      return _otp
    }
  }
})()
