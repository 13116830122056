var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"9e3a9a14793df42ff162fc944cfa0cc2f6107603"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

/*
 * IMPORTS
 */
import * as Sentry from '@sentry/nextjs' // NPM: Sentry library for next.js


/*
 * INITIALIZATION
 */
Sentry.init({
  'dsn': process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN,
  'tracesSampleRate': 1.0
})
