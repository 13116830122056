/*
 * IMPORTS
 */
import React from 'react' // NPM: React.js library.
import Head from 'next/head' // NPM: Next.js head for html content.
import NextNprogress from 'nextjs-progressbar' // NPM: next.js progress bar.
import { Provider } from 'react-redux' // NPM: react redux library.
import { PersistGate } from 'redux-persist/integration/react' // NPM: react persist library.
import { ThemeProvider } from 'styled-components' // NPM: styled-components for react.js
import { setContext } from '@apollo/client/link/context' // NPM: Apollo client index.context.js library.
import { WebSocketLink } from '@apollo/client/link/ws' // NPM: Apollo client subscriptions.
import { getMainDefinition } from '@apollo/client/utilities' // NPM: Apollo client utility.
import { Manrope } from '@next/font/google' // NPM: Google font library.
import { useRouter } from 'next/router' // NPM: Next.js router library.
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  split
} from '@apollo/client' // NPM: Apollo client for react.js.


/*
 * PACKAGES
 */
import '~packages/iife'
import Context from '~packages/context'
import Redux, { Persist } from '~packages/redux'


/*
 * STYLES
 */
import '@glidejs/glide/dist/css/glide.core.min.css'
import GlobalStyle from '~packages/common/style'


/*
 * GLOBALS
 */
const _Manrope = Manrope({
  display: 'swap',
  weight: [
    '200',
    '300',
    '400',
    '500',
    '600',
    '700',
    '800'
  ],
  preload: true
})


/*
 * OBJECTS
 */
const Index = ({ Component, pageProps }) => {
  // Hook assignment.
  const [theme, setTheme] = React.useState(Redux.getState().Theme)
  const [account, setAccount] = React.useState(Redux.getState().Account)
  const [context, setContexts] = React.useState({})
  const _routerRef = useRouter()
  const _apolloClientRef = React.useRef({})

  // Object assignment.
  const _HttpLink = createHttpLink({
    'uri': process.env.NEXT_PUBLIC_HTTP_LINK_HASURA
  })
  const _WsLink = process.browser ? new WebSocketLink({
    'uri': process.env.NEXT_PUBLIC_WS_LINK_HASURA,
    'options': {
      'reconnect': true,
      'lazy': false,
      'connectionParams': {
        'headers': {
          'x-hasura-admin-secret': process.env.NEXT_PUBLIC_HASURA_SECRET
        }
      }
    }
  }) : void 0
  const _AuthLink = setContext((_, { headers }) => ({
    'headers': {
      ...headers,
      'x-hasura-admin-secret': process.env.NEXT_PUBLIC_HASURA_SECRET
    }
  }))

  /*
   * Create apollo connection with web socket
   * http link.
   */
  _apolloClientRef.current = process.browser ? new ApolloClient({
    'link': _AuthLink.concat(split(
      // Split based on operation type
      ({ query }) => {
        const { kind, operation } = getMainDefinition(query)

        return 'OperationDefinition' === kind && 'subscription' === operation
      },
      _WsLink,
      _HttpLink
    )),
    'cache': new InMemoryCache()
  }) : void 0

  // Event handler.
  Redux.subscribe(() => {
    // Update state of theme.
    setTheme(Redux.getState().Theme)
    setAccount(Redux.getState().Account)
  })

  React.useEffect(() => {
    /*
     * If user is not authorized then send
     * user to login page before proceeding.
     */
    if (!account.isUserLoggedIn && '/login/' !== _routerRef.asPath) _routerRef.push('/login')

    // If document is already ready.
    if ('complete' === document.readyState) {
      // Watch for event when user system theme changes.
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
        // Const assignment.
        const _scheme = event.matches ? 'dark' : 'light'

        /*
         * Only update theme if it doesn't matches
         * with a theme available.
         */
        if (_scheme !== theme.mode) {
          /*
           * Update website color theme based on user
           * preferences.
           */
          Redux.dispatch({ 'type': 'THEME_UPDATE', 'Theme': { 'mode': _scheme } })
        }
      })
    }
  }, [account, _routerRef, theme])

  // Return component.
  return (
    <Provider store={Redux}>
      <PersistGate persistor={Persist}>
        <ThemeProvider theme={theme}>
          <ApolloProvider client={_apolloClientRef.current}>
            <Context.Provider value={[context, setContexts]}>
              <Head>
                <title>App</title>
                <meta name='application-name' content='garbit.co.in' />
                <meta name='theme-color' content={theme.colors.primary} />
                <meta name='apple-mobile-web-app-capable' content='yes' />
                <meta name='apple-mobile-web-app-status-bar-style' content='default' />
                <meta name='apple-mobile-web-app-title' content='garbit.co.in' />
                <meta name='description' content='garbage collection platform' />
                <meta name='format-detection' content='telephone=no' />
                <meta name='mobile-web-app-capable' content='yes' />
                <meta name='msapplication-config' content='/static/browserconfig.xml' />
                <meta name='msapplication-TileColor' content={theme.colors.black} />
                <meta name='msapplication-tap-highlight' content='no' />
                <meta name='viewport' content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, maximum-scale=5, viewport-fit=cover' />
                <meta property='og:type' content='website' />
                <meta property='og:title' content='garbit.co.in' />
                <meta property='og:description' content='Website development and it services.' />
                <meta property='og:site_name' content='garbit.co.in' />
                <meta property='og:url' content='https://www.garbit.co.in/' />
                <meta property='og:image' content='/static/images/apple-touch-icon.png' />
                <link rel='icon' type='image/svg+xml' href='/static/images/favicon.svg' />
                <link rel='icon' type='image/png' href='/static/images/favicon.png' />
                <link rel='apple-touch-icon' sizes='180x180' href='/static/images/apple-touch-icon.png' />
                <link rel='icon' type='image/png' sizes='32x32' href='/static/images/favicon-32x32.png' />
                <link rel='icon' type='image/png' sizes='16x16' href='/static/images/favicon-16x16.png' />
                <link rel='icon' type='image/x-icon' href='/static/images/favicon.ico'/>
                <link rel='manifest' href='/static/images/site.webmanifest' />
                <link rel='mask-icon' href='/static/image/safari-pinned-tab.svg' color={theme.colors.black} />
                <meta name='msapplication-TileColor' content={theme.colors.black} />
                <meta name='viewport' content='width=device-width, initial-scale=1' />
              </Head>
              <GlobalStyle />
              <section className={_Manrope.className}>
                <NextNprogress
                  color={theme.colors.secondary}
                  startPosition={0.3}
                  stopDelayMs={200}
                  height={3}
                  showOnShallow={true}
                  options={{ 'showSpinner': false }}
                />
                <Component {...pageProps} />
              </section>
            </Context.Provider>
          </ApolloProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  )
}


/*
 * EXPORTS
 */
export default Index
